<template>
    <div>
        <div class="container">
            <edit api="features" title="feature" :id="id" :fs="couponFields" back="/admin/features" @saved="saved"></edit>
        </div>
    </div>
</template>

<script>
import Edit from "@/components/Edit";
import { featureFields } from "@/config";

export default {
    props: ["id"],
    data() {
        return {
            couponFields: featureFields
        };
    },
    components: {
        Edit
    },
    methods: {
        saved() {
            this.$router.push("/admin/features");
        }
    }
};
</script>